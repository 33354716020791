<template>
  <div class="main" data-title="修改密码" v-title>
    <el-card class="main-content" shadow="hover">
      <div class="menu-box">
        <left-menu class="left-menu"></left-menu>
        <div class="right-main">
          <div class="head">
            <div class="head-title">修改密码</div>
          </div>

          <div class="body">
            <div class="form-main">
              <el-form
                :model="form"
                :rules="rules"
                class="form"
                label-position="top"
                label-width="150px"
                ref="form"
              >
                <el-form-item label="原密码" prop="old_pwd">
                  <el-input suffix-icon="el-icon-key" type="password" v-model="form.old_pwd"></el-input>
                </el-form-item>

                <el-form-item label="新密码" prop="new_pwd">
                  <el-input suffix-icon="el-icon-edit" type="password" v-model="form.new_pwd"></el-input>
                </el-form-item>

                <el-form-item label="再次输入密码" prop="checkPass">
                  <el-input suffix-icon="el-icon-edit" type="password" v-model="form.checkPass"></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button @click="submitForm('form')" type="primary">更新密码</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import LeftMenu from '@/components/LeftMenu.vue'
export default {
  components: {
    LeftMenu
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        // let pattern = new RegExp('^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$')
        // if (!pattern.test(value)) {
        //   callback(new Error('密码由8-20位字母和数字组成'))
        // }
        if (value.length < 8 || value.length > 20) {
          callback(new Error('密码由8-20位字母或数字组成'))
        }
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.new_pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var isEmpty = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'))
      }
      callback()
    }
    return {
      form: {
        old_pwd: '',
        new_pwd: '',
        checkPass: ''
      },
      rules: {
        old_pwd: [{ validator: isEmpty, trigger: 'blur' }],
        new_pwd: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post('/user/change_pwd', this.form).then(res => {
            if (res.data.code == '200') {
              this.$message.success(res.data.msg)
              this.$router.push({
                name: 'login'
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.main-content {
  /deep/ .el-card__body {
    padding-left: 0;
    padding-right: 0;
  }
}
.w100 {
  width: 100%;
}
.menu-box {
  display: flex;
  .left-menu {
    width: 224px;
  }
  .right-main {
    margin: 8px 40px;
    flex: 1;
    .head {
      display: flex;
      margin-bottom: 24px;
      .head-title {
        font-weight: 500;
        font-size: 20px;
        flex: 1;
      }
      .head-btns {
        display: flex;
        align-items: center;
        i.el-icon-check {
          font-size: 20px;
          color: #409eff;
        }
      }
    }
  }
}
.body {
  /deep/ .el-form-item__label {
    padding: 0;
  }
  display: flex;
  .form-main {
    width: 310px;
  }
}
</style>