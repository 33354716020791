<template>
  <div class="left-menu">
    <router-link
      :class="curr == 'company-setting' ? 'curr' : ''"
      :to="{name:'company-setting'}"
      class="left-menu-item"
    >个人信息</router-link>
    <router-link
      :class="curr == 'user-password' ? 'curr' : ''"
      :to="{name:'user-password'}"
      class="left-menu-item"
    >修改密码</router-link>
    <router-link
      :class="curr == 'user-msg' ? 'curr' : ''"
      :to="{name:'user-msg'}"
      class="left-menu-item"
    >消息通知</router-link>
  </div>
</template>
<script>
export default {
  data() {
    return {
      curr: ''
    }
  },
  mounted() {
    this.getCurr()
  },
  methods: {
    getCurr() {
      this.curr = this.$route.name
    }
  },
  watch: {
    $route() {
      this.getCurr()
    }
  }
}
</script>
<style lang="less" scoped>
.left-menu {
  border-right: 1px solid #e8e8e8;
  .left-menu-item {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 8px;
    &:hover {
      color: #409eff;
    }
    &.curr {
      background: #e6f7ff;
      font-weight: 500;
      color: #409eff;
      border-right: 3px #409eff solid;
    }
  }
}
</style>